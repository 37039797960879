import  Cookies  from 'js-cookie';
import { NestedError } from './../models/reponse';
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { format, parseISO } from "date-fns";
import { fr } from "date-fns/locale";
// import { redirect } from "next/navigation";
import { toast } from "sonner";
import { ToastOptions } from 'react-toastify';
import { PromotionalCampagne } from '../models/campagne-model';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const ACCEPTED_IMAGE_MIME_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];
export function getFormattedDate(
  dateTime: string,
  day: boolean,
  hour: boolean,
) {
  const date = new Date(dateTime);

  const localHours = date.getHours();
  const localMinutes = date.getMinutes();

  const offset = date.getTimezoneOffset() / 60;

  const adjustedHours = localHours - offset;
  const adjustedMinutes = localMinutes;

  const h =
    String(date.getHours()).padStart(2, "0") +
    ":" +
    String(date.getMinutes()).padStart(2, "0");
  const d = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  if (day && !hour) {
    return d;
  } else if (hour && !day) {
    return h;
  } else {
    return d + " " + h;
  }
}

export function getFormattedDateWithSlache(
  dateTime: string,
  day: boolean,
  hour: boolean,
  subtract30Minutes: boolean = false,
) {
  const date = new Date(dateTime);

  if (subtract30Minutes) {
    date.setMinutes(date.getMinutes() - 30);
  }
  const h = ` ${date.getHours()}:${date.getMinutes()}`;
  const d = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

  if (day && !hour) {
    return d;
  } else if (hour && !day) {
    return h;
  } else {
    return d + " " + h;
  }
}

export function getFormattedDateFr(
  dateTime: string,
  day: boolean,
  hour: boolean,
) {
  const date = new Date(dateTime);

  if (day && !hour) {
    return format(date, "EEEE, d MMM yyyy", { locale: fr });
  } else if (hour && !day) {
    return format(date, "HH:mm");
  } else {
    return format(date, "EEEE, d MMM yyyy HH:mm", { locale: fr });
  }
}

export function headers({ contentType = "application/json" }: { contentType?: string } = {}) {
  if (typeof window !== 'undefined') {
    const token = Cookies.get("token");
    const headers: Record<string, string> = {
      "Content-Type": contentType
    };
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    return headers;
  }

  return {
    "Content-Type": contentType
  };
}

export function headersStatic({ contentType = "application/json" }: { contentType?: string } = {}) {
  const token = Cookies.get("token");

  return {
    "Content-Type": contentType,
    "Authorization": `Bearer ${token}`,
  };
}
export interface ErrorResponse {
  errors: Array<{ errors: string }> | { errors: string };
}

export function handleResponseErrors(errors: NestedError | NestedError[]): void {
  if (
    errors &&
    errors instanceof Array &&
    errors.length > 0
  ) {
    // If response.errors is a non-empty array
    const errorMessage = errors
      .map((error) => error.errors)
      .join(", ");
    toast.error(errorMessage);
  } else if (errors && !Array.isArray(errors)) {
    // If response.errors is a single NestedError
    toast.error(errors.errors);
  } else {
    // If no errors are present, display a generic error message
    toast.error("Une erreur inattendue s'est produite.");
  }
}

export function getDaysBetweenDates(d0: string, d1: string): number
{

  var msPerDay = 8.64e7;
  var x0 = new Date(d0);
  var x1 = new Date(d1);

  // Set to noon - avoid DST errors
  x0.setHours(12,0,0);
  x1.setHours(12,0,0);

  // Round to remove daylight saving errors
  return Math.round( (x1.valueOf() - x0.valueOf()) / msPerDay );
}

export function getDateFromTimestamp (timestamp: number) {
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString(
    'en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour:'numeric', minute:"numeric" }
  )
}
export function notify (type: string, message: any) {
  if (type == "error") {
    toast.error(message);
  } else {
    toast.success(message);
  }
}

export const toastConfig: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  // transition: Bounce,
  };

export function displayFrequencyType(frequencyType: string) {
    switch (frequencyType) {
      case "ONE_TIME":
        return "Envoi Unique";
        break;
      case "DAILY":
        return "Journalier"
        break;
      case "MONTHLY":
        return "Mensuel";
        break;
      case "YEARLY":
        return "Annuel";
        break;
      case "WEEKLY":
        return "Hebdomadaire";
        break;
      
      default:
        break;
    }
  }

export function sortWeekDays(day:string) {
    switch (day) {
      case "MON":
        return "LUNDI";
        break;
      case "TUE":
        return "MARDI";
        break;
      case "WED":
        return "MERCREDI";
        break;
      case "THU":
        return "JEUDI";
        break;
      case "FRI":
        return "VENDREDI";
        break;
      case "SAT":
        return "SAMEDI";
        break;
      case "SUN":
        return "DIMANCE";
        break;
      
      default:
        break;
    }
  }

export function extractTime(datetimeString: string): string {
    // const dateObject = new Date(datetimeString);
    // const hours = String(dateObject.getHours()).padStart(2, '0');
    // const minutes = String(dateObject.getMinutes()).padStart(2, '0');
    // const seconds = String(dateObject.getSeconds()).padStart(2, '0');
    var arr = datetimeString.split("T");
    return arr[1].substring(0,8); 
  
  }
  
//Conversations
export const DASHBOARD = "#";

//Conversations
export const CONVERSATION_MESSAGES = "#";
export const CONVERSATION_DASHBOARD = "#dashboard";
export const CONVERSATION_HISTORY = "#history";
export const CONVERSATION_CONTACTS = "#contacts";

//Conversations
export const CAMPAGNE_DASHBOARD = "#";
export const CAMPAGNE_FOLLOW = "#cpg-follow";
export const CAMPAGNE_NEW = "#cpg-new";
export const CAMPAGNE_CONTACT = "#cpg-contact";
export const CAMPAGNE_TEMPLATE = "#cpg-template";
export const CAMPAGNE_NEW_TEMPLATE = "#cpg-new-template";
export const CAMPAGNE_MEDIA = "#cpg-media";

//CRM CLIENT
export const QUOTE_REQUESTS = "#";

//FINANCE
export const FINANCES_DASHBOARD = "#";

import { ChatMessage, InputFormMessage, MenuList } from "../../../components";

import {
  ArrowsRightLeftIcon,
  BellSlashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  PencilIcon,
  TrashIcon,
  EllipsisVerticalIcon,
  TicketIcon,
  FilmIcon,
  CalculatorIcon,
} from "@heroicons/react/24/outline";
import { useLayoutContext } from "../../../core/context/layout-context";
import { Messages } from "../../../layouts";
import AsideInfo from "../../../layouts/aside-info";
import React from "react";
import { WhatsappContact } from "../../../models/contact-model";
import { getContactAvatar, getContactDetails, getTemplates } from "../../../services/chat-service";
import NoOpenChat from "../../../components/no-open-chat";
import { Message as MessageModel } from "../../../models/message-model";
import { toast, ToastContainer } from "react-toastify";
import { toastConfig } from "../../../utils/utils";
import { NotificationTemplate } from "../../../models/notification-template-model";

interface MessageProps {
  showContactList?: boolean;
}
 
export default function Message({ showContactList }: MessageProps) {
  const { asideOpen } = useLayoutContext();
  const [openChat, setOpenChat] = React.useState<number>(0);
  const [phone, setPhone] = React.useState<string>("");
  const [contact, setContact] = React.useState<WhatsappContact>();
  const [avatar, setAvatar] = React.useState<string>("");
  const [chats, setChats] = React.useState<MessageModel>();
  // const [textMessage, setTextMessage] = React.useState<string>();
  const [refreshChat, setRefreshChat] = React.useState<boolean>(false);
  const [avatarChecked, setAvatarChecked] = React.useState<Boolean>(false);
  const [templates, setTemplates] = React.useState<Array<NotificationTemplate>>([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState<NotificationTemplate>();
  const [templateVariableValues, setTemplateVariableValues] = React.useState({});
  const [templatesFetched, setTemplatesFetched] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);
  React.useEffect(() => {
    if (typeof phone == "string" || phone != "") {
      getContactDetails(phone).then((contact) => {
        setContact(contact);
        if (contact.id === "" && contact.number === "") {
          toast.error("Ce Numero n'a pas de compte Whatasapp", toastConfig)
        }
      });
      getContactAvatar(phone).then((avatar) => {
        if (typeof avatar != "undefined") {
          setAvatar(avatar);
        } else {
          setAvatar("/assets/images/img-1.jpg")
        }
        setAvatarChecked(true);
      });
    }
    if (!templatesFetched) {
      getTemplates().then((data: NotificationTemplate[]) => {
        setTemplates(data);
        setTemplatesFetched(true);
      }).catch((e) => {
        toast.error("Une Erreur Technique s'est Lors de la recuperation des templates", toastConfig)
      })
    }
  },[phone, refreshChat]);
  return (
    <main className="xl:px-80">
      <div className="h-screen">
        <div className={"flex flex-col sticky top-10 w-full bg-white "}>
        {phone != "" && typeof phone == "string"?(
            <>
              <div className="px-4 sm:px-6 lg:px-4 py-10 lg:py-[14px]">
                <div className="flex justify-between items-center w-full">
                  <div className="flex items-center gap-x-4 min-w-full">
                    {avatar == "" || typeof avatar == "undefined" ? (
                        <img
                          className="rounded-md h-12 w-12"
                          src="/assets/images/img-1.jpg"
                          alt=""
                        />
                        
                      ):(
                        <img
                          className="rounded-md h-12 w-12"
                          src={avatar}
                          alt=""
                        />
                    )}
                    <div className="flex flex-col md:flex-row md:items-center gap-2 justify-between w-full">
                      <div className="flex flex-col w-full">
                        <h3 className="text-secondary-100 text-lg font-semibold">
                          {contact?.name} {contact?.isMe?"(VOUS)":""}
                        </h3>
                        <span className="flex items-center gap-x-1 text-xs text-gray-500 font-normal">
                          <hr className="p-1 bg-green-400 rounded-full" /> Online
                        </span>
                      </div>
                      <div className="flex items-center gap-x-2">
                        <div className="flex flex-row items-center gap-2 w-40">
                          <button
                            type="button"
                            className="bg-yellow-100 hover:bg-yellow-200 text-yellow-500 flex text-xs items-center px-2 py-1 rounded-md gap-x-2"
                          >
                            Non Résolu
                          </button>
                          <button
                            type="button"
                            className="bg-green-100 hover:bg-green-200 text-green-500 flex text-xs items-center px-2 py-1 rounded-md gap-x-2"
                          >
                            Assigner
                          </button>
                        </div>
                        <MenuList
                          items={[
                            {
                              icon: TicketIcon,
                              name: "Ajouter Ticket",
                            },
                            {
                              icon: FilmIcon,
                              name: "Catalogue",
                            },
                            {
                              icon: CalculatorIcon,
                              name: "Devis",
                              separator: true
                            },
                            {
                              icon: CheckCircleIcon,
                              name: "Résolu",
                            },
                            {
                              icon: PencilIcon,
                              name: "Epinglé",
                            },
                            {
                              icon: BellSlashIcon,
                              name: "Silencieux",
                            },
                            {
                              icon: TrashIcon,
                              name: "Supprimer",
                            },
                            {
                              icon: ArrowsRightLeftIcon,
                              name: "Assigner",
                            },
                          ]}
                          icon={
                            <EllipsisVerticalIcon className="h-6 w-6 text-gray-700" />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ):(
            <></>
          )}
          
          <hr />
        </div>
        
        <div className={"flex-grow h-full w-full flex flex-col mt-16 " + (phone != "" && typeof phone == "string"?"":"justify-center items-center")}>
          {phone != "" && typeof phone == "string"?(
            <>
            <ChatMessage phone={phone} avatar={avatar} setChats={setChats} refresh={refreshChat} />
            <InputFormMessage 
              phone={phone} 
              setRefreshChat={setRefreshChat} 
              setTemplateContent={(values) => setTemplateVariableValues(values)}
              setTemplate={(t) => {
                setSelectedTemplate(t)
              }}
              templates={templates}
              template={selectedTemplate}
              setShowPreview={(s) => setShowPreview(s)}
            />
            </>
          ):(
            <NoOpenChat />
          )}
        </div>
      </div>
      <AsideInfo contact={contact} avatar={avatar} template={selectedTemplate} showPreview={showPreview} templateContent={templateVariableValues} />
      <Messages
        chatDisplay={openChat}
        openChat={(i) => setOpenChat(i)}
        openAside={asideOpen}
        setPhone={(p) => setPhone(p)}
        showContactList={showContactList}
      />
      <ToastContainer
       position="top-right"
       autoClose={5000}
       
       hideProgressBar={false} // Other desired configurations
       newestOnTop={false}
       closeOnClick
       rtl={false}
       pauseOnFocusLoss
       draggable
       pauseOnHover
       theme="light"
     />
    </main>
  );
}

import { replace } from "lodash";
import { WhatsappContact } from "../models/contact-model";
import { Chat, Message } from "../models/message-model";
import { ApiRoute } from "../utils/api-route";
import { headers } from "../utils/utils";
import { Auth } from "../models/auth-model";

export async function login(body: any): Promise<Auth> {
    try {
      const response = await fetch(ApiRoute.login , {
        method: "POST",
        body: JSON.stringify(body),
        headers: headers()
      });
  
      if (response.status != 200) {
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      const userData: Auth = data.data;
  
      console.log("data", userData);
  
      return userData;
    } catch (e) {
      console.log("Une erreur c'est produite.", e);
  
      throw new Error(JSON.stringify(e));
    }
  }

export async function logout(): Promise<boolean> {
    try {
      const response = await fetch(ApiRoute.logout , {
        method: "POST",
        headers: headers()
      });
  
      if (response.status != 200) {
        throw new Error(response.status.toString());
      }
      return true
      
    } catch (e) {
      console.log("Une erreur c'est produite.", e);
  
      return false;
    }
  }

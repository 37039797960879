//app base url

export class ApiRoute {
  static apiBaseUrl = process.env.REACT_APP_DIGIASSUR_BASE_URL + "/api";

  static searchPassenger = this.apiBaseUrl + "/passagers/passager/search";

  static login = this.apiBaseUrl + "/login_check";
  
  static logout = this.apiBaseUrl + "/logout";

  static campagnes = this.apiBaseUrl + "/campain";

  static promotional_campagnes = this.apiBaseUrl + "/promotional-campains";

  // chat direct to 2ways

  // static ultramessageChat = this.apiBaseUrl + "/ultra-message/chat";

  // static sendText = this.apiBaseUrl + "/messages/text";

  // static sendMedia = this.apiBaseUrl + "/media";

  // static getChats = this.apiBaseUrl + "/messages/list/";

  // static getMessages = this.apiBaseUrl + "/chats/messages?chatId=";

  // static checkPhone = this.apiBaseUrl + "/contacts/check?chatId=";

  // static chatList = this.apiBaseUrl + "/chats";

  // static contacts = this.apiBaseUrl + "/contacts";

  // chat via digiassur back

  static ultramessageChat = this.apiBaseUrl + "/ultra-message/chat";

  static sendText = this.ultramessageChat + "/text";

  static sendMedia = this.ultramessageChat + "/media";

  static getChats = this.ultramessageChat + "/get-chats";

  static getMessages = this.ultramessageChat + "/get-messages/";

  static checkPhone = this.ultramessageChat + "/check-phone";

  static chatList = this.ultramessageChat + "/chat-list";

  static contacts = this.ultramessageChat + "/contacts";

  static database_contacts = this.apiBaseUrl + "/contacts";
  
  static catalogs = this.apiBaseUrl + "/catalog";

  static templates = this.apiBaseUrl + "/templates";

  static template_chats = this.apiBaseUrl + "/chat";

  

  
}

export class ApiRouteSecond {
  static apiBasUrl = process.env.REACT_APP_DIGIASSUR_BASE_URL;

  static dashbordinfo = this.apiBasUrl + "/dashboard-info";
}
